import * as React from "react";

import { useNavigate } from "react-router";
import { Link, NavLink } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import Button from '@mui/material/Button';
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MoreIcon from "@mui/icons-material/MoreVert";
import Divider from "@mui/material/Divider";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import LogoutIcon from '@mui/icons-material/Logout';

import logo from "./../../assets/images/logo.png";
import AppTopBar from "./topBar";
import { AuthContext } from "../../context/AuthContext";
import { ConfigContext } from "../../context/ConfigContext";
import useAuthService from "../../services/shared/authService";


const AppBarComponent = () => {
  const navigate = useNavigate();

  const { user, commune, loggedIn } = React.useContext(AuthContext);
  const { activeModule } = React.useContext(ConfigContext);
  const { logout } = useAuthService();

  const [menuItems, setMenuItems] = React.useState([]);
  const [menuAnchorEl, setMenuAnchorEl] = React.useState();
  const isMenuOpen = Boolean(menuAnchorEl);

  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState();
  const isUserMenuOpen = Boolean(userMenuAnchorEl);

  const handleMenuOpen = React.useCallback((event, index) => {
    // console.log(index);

    if (index === 2) {
      setMenuItems([
        { id: 21, text: "Bâtiments", url: "/patrimoine/batiments" },
        { id: 22, text: "Eclairage public", url: "/patrimoine/eclairage" },
        { id: 23, text: "Parc roulant", url: "/patrimoine/parc" },
      ]);
    } else if (index === 3) {
      setMenuItems([
        { id: 31, text: "Globale", url: "/consommations/commune" },
        { id: 32, divider: true },
        { id: 33, text: "Bâtiments", url: "/consommations/batiments" },
        { id: 34, text: "Armoires", url: "/consommations/armoires" },
        { id: 35, text: "Compteurs", url: "/consommations/compteurs" },
        { id: 36, text: "Véhicules/Engins", url: "/consommations/vehicules" },
      ]);
    } else if (index === 4) {
      let items = [
        { id: 41, text: "National", url: `/dashboards${loggedIn && commune ? "" : "/public"}/national` },
        { id: 42, text: "Gouvernorats", url: `/dashboards${loggedIn && commune ? "" : "/public"}/gouvernorat` },
        { id: 43, text: "Communes", url: `/dashboards${loggedIn && commune ? "" : "/public"}/commune` },
      ];

      if (loggedIn && commune) {
        setMenuItems([
          ...items,
          { id: 44, divider: true },
          { id: 45, text: "Bâtiments", url: "/dashboards/batiments" },
          { id: 46, text: "Eclairage public", url: "/dashboards/eclairage" },
          { id: 47, text: "Parc roulant", url: "/dashboards/parc" },
        ]);
      } else {
        setMenuItems([...items]);
      }
    } else if (index === 9) {
      setMenuItems([
        { id: 91, text: "Patrimoine: Bâtiments", url: "/patrimoine/batiments" },
        { id: 92, text: "Patrimoine: Eclairage public", url: "/patrimoine/eclairage" },
        { id: 93, text: "Patrimoine: Parc roulant", url: "/patrimoine/parc" },
        { id: 94, divider: true },
        { id: 95, text: "Consommation: Globale", url: "/consommations/commune" },
        { id: 96, divider: true },
        { id: 97, text: "Consommation: Bâtiments", url: "/consommations/batiments" },
        { id: 98, text: "Consommation: Armoires", url: "/consommations/armoires" },
        { id: 99, text: "Consommation: Compteurs", url: "/consommations/compteurs" },
        { id: 100, text: "Consommations: Véhicules/Engins", url: "/consommations/vehicules" },
      ]);
    }

    setMenuAnchorEl(event.currentTarget);
  }, [commune, loggedIn]);

  const handleMenuClose = React.useCallback(() => {
    setMenuAnchorEl(null);
  }, []);

  const handleMenuItemClik = React.useCallback((url) => {
    setMenuAnchorEl(null);
    setUserMenuAnchorEl(null);
    navigate(url);
  }, [navigate]);

  const handleProfileMenuOpen = React.useCallback((event) => {
    setUserMenuAnchorEl(event.currentTarget);
  }, []);

  const handleProfileMenuClose = React.useCallback(() => {
    setUserMenuAnchorEl(null);
  }, []);

  const handleLogoutClick = React.useCallback((url) => {
    setUserMenuAnchorEl(null);
    logout();
  }, [logout]);

  return (
    <>
      {/* <Box sx={{ flexGrow: 1 }}> */}
      <AppBar position="static" className="app-header">

        <AppTopBar />
        <Toolbar className="nav-toolbar">
          <Grid container direction="column" alignItems="center">
            <Box sx={{ width: "90%" }} >
              <Grid
                container
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
              >
                <Link to="/index">
                  <img
                    src={logo}
                    alt="Acceuil"
                    className="nav-link"
                    
                  />
                </Link>
                {commune ? (
                  <>
                    <Box sx={{ display: { xs: "flex", md: "none" } }}>
                      <IconButton
                        size="large"
                        aria-label="Afficher d'autres"
                        id="more-link"
                        aria-controls={"nav-mobile-menu"}
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleMenuOpen(event, 9);
                        }}
                        className="nav-button"
                      >
                        <MoreIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ display: { xs: "none", md: "flex" } }}>
                      <NavLink
                        to={"/commune/informations"}
                        id="commune-link"
                        aria-label="Commune"
                        className={({ isActive, isPending }) =>
                          "nav-link " + (isActive || activeModule === 1 ? "active" : "")
                        }
                      >
                        Commune
                      </NavLink>

                      <Button
                        size="small"
                        id="patrimoine-link"
                        className={`nav-link ${activeModule === 2 ? "active" : ""}`}
                        endIcon={<ArrowDropDownIcon />}
                        aria-label="Patrimoine"
                        aria-controls="nav-patrimoine-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleMenuOpen(event, 2);
                        }}
                      >
                        Patrimoine
                      </Button>

                      <Button
                        size="small"
                        id="consommation-link"
                        className={`nav-link ${activeModule === 3 ? "active" : ""}`}
                        endIcon={<ArrowDropDownIcon />}
                        aria-label="Consommations"
                        aria-controls="nav-consommation-menu"
                        aria-haspopup="true"
                        onClick={(event) => {
                          handleMenuOpen(event, 3);
                        }}
                      >
                        Consommations
                      </Button>
                    </Box>
                  </>
                ) : (
                  ""
                )}
                <Box  >
                  <Button
                    size="small"
                    id="dashbord-link"
                    className={`nav-link ${activeModule === 4 ? "active" : ""}`}
                    endIcon={<ArrowDropDownIcon />}
                    onClick={(event) => {
                      handleMenuOpen(event, 4);
                    }}
                  >
                    Tableaux de bord
                  </Button>
                </Box>
                <Box sx={{ flexGrow: 1 }} />
                {loggedIn ? (
                  <Box >
                    <IconButton
                      size="large"
                      edge="end"
                      id="user-link"
                      aria-label="Utilisateur"
                      aria-controls="nav-profile-menu"
                      aria-haspopup="true"
                      onClick={handleProfileMenuOpen}
                      className="nav-button"

                    >
                      <AccountCircle />
                      <ArrowDropDownIcon />
                    </IconButton>
                  </Box>
                ) : ("")}

              </Grid>
            </Box>
          </Grid>
        </Toolbar>
      </AppBar>
      {/* </Box> */}

      <Menu
        id={"nav-commune-menu"}
        anchorEl={menuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        {menuItems.map((item) => {
          if (item?.divider) {
            return (
              <Divider key={item.id} />
            )
          } else {
            return (
              <MenuItem
                key={item.id}
                className="sm-text"
                onClick={() => {
                  handleMenuItemClik(item.url);
                }}
              >
                {item.text}
              </MenuItem>
            );
          }
        })}
      </Menu>

      <Menu
        id={"nav-profile-menu"}
        anchorEl={userMenuAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isUserMenuOpen}
        onClose={handleProfileMenuClose}
      >
        <MenuItem key={1} className="small-text">{user?.nom}</MenuItem>
        <Divider />
        <MenuItem key={2} className="small-text" onClick={() => {handleMenuItemClik("/help")}}>Aide</MenuItem>
        <Divider />
        <MenuItem key={3} className="small-text" onClick={handleLogoutClick}>
          <LogoutIcon fontSize="small" sx={{ marginRight: ".5rem" }} />
          Se déconnecter
        </MenuItem>
      </Menu>
    </>
  );
};

export default AppBarComponent;
