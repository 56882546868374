import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import Loadable from "../_utils/Loadable";


const CommuneIndex = Loadable(
  lazy(() =>
    import("../views/commune/index")
  )
);

const BatimentsIndex = Loadable(
  lazy(() => import("../views/patrimoine/batiments"))
);

const BatimentDetails = Loadable(
  lazy(() =>
    import(
      "../views/patrimoine/batiments/batiments/batimentDetails"
    )
  )
);
const AddEditBatiment = Loadable(
  lazy(() =>
    import(
      "../views/patrimoine/batiments/batiments/addEditBatiment"
    )
  )
);

const ParcIndex = Loadable(
  lazy(() => import("../views/patrimoine/parcs/index"))
);

const VehiculeDetails = Loadable(
  lazy(() => import("../views/patrimoine/parcs/vehicules/vehiculeDetails"))
);

const AddEditVehicule = Loadable(
  lazy(() => import("../views/patrimoine/parcs/vehicules/addEditVehicule"))
);


const EclairagePublicIndex = Loadable(
  lazy(() => import("../views/patrimoine/eclairagePublic/index"))
);


const ArmoireDetais = Loadable(
  lazy(() =>
    import("../views/patrimoine/eclairagePublic/armoires/armoireDetails")
  )
);

const AddEditArmoire = Loadable(
  lazy(() =>
    import("../views/patrimoine/eclairagePublic/armoires/addEditArmoire")
  )
);

const DepartDetais = Loadable(
  lazy(() =>
    import("../views/patrimoine/eclairagePublic/departs/departDetails")
  )
);


const GlobalConsIndex = Loadable(
  lazy(() =>
    import("../views/consommations/global"))
);

const BatimentsConsIndex = Loadable(
  lazy(() =>
    import("../views/consommations/details/batiments"))
);

const ArmoiresConsIndex = Loadable(
  lazy(() =>
    import("../views/consommations/details/armoires"))
);

const CompteursConsIndex = Loadable(
  lazy(() =>
    import("../views/consommations/details/compteurs"))
);

const VehiculesConsIndex = Loadable(
  lazy(() =>
    import("../views/consommations/details/vehicules"))
);

const PatrimoineDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/patrimoine"))
);

const CommuneDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/communes"))
);

const GouvDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/gouvernorats"))
);

const NationalDashboardIndex = Loadable(
  lazy(() =>
    import("../views/dashboard/national"))
);

const HelpIndex = Loadable(
  lazy(() =>
    import("../views/help"))
);


// ===========================|| PRIVATE ROUTING ||=========================== //

function PrivateRoutes() {

  const routes = [
    {
      path: "/commune",
      element: <Outlet />,
      children: [
        {
          path: "informations",
          element: <CommuneIndex />,
        },
      ],
    },
    {
      path: "/patrimoine",
      element: <Outlet />,
      children: [
        // batiments
        {
          path: "batiments",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <BatimentsIndex />,
            },
            {
              path: ":tab",
              element: <BatimentsIndex />,
            },
            {
              path: "batiments",
              element: <Outlet />,
              children: [
                {
                  path: "add",
                  element: <AddEditBatiment />,
                },
                {
                  path: ":id/edit",
                  element: <AddEditBatiment />,
                },
                {
                  path: ":id/detail",
                  element: <BatimentDetails />,
                },
              ]
            },
          ],
        },
        // parc
        {
          path: "parc",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <ParcIndex />,
            },
            {
              path: "vehicules",
              element: <Outlet />,
              children: [
                {
                  path: "add",
                  element: <AddEditVehicule />,
                },
                {
                  path: ":id/edit",
                  element: <AddEditVehicule />,
                },
                {
                  path: ":id/detail",
                  element: <VehiculeDetails />,
                },
              ]
            },
          ],
        },
        // eclairage
        {
          path: "eclairage",
          element: <Outlet />,
          children: [
            {
              index: true,
              element: <EclairagePublicIndex />,
            },
            {
              path: ":tab",
              element: <EclairagePublicIndex />,
            },
            {
              path: "armoires",
              element: <Outlet />,
              children: [
                {
                  path: "add",
                  element: <AddEditArmoire />,
                },
                {
                  path: ":id/detail/:tab",
                  element: <ArmoireDetais />,
                },
                {
                  path: ":id/edit",
                  element: <AddEditArmoire />,
                },
                {
                  path: ":idArmoire/departs/:id/detail",
                  element: <DepartDetais />,
                },
              ]
            },
          ],
        },
      ]
    },
    {
      path: "/consommations",
      element: <Outlet />,
      children: [
        {
          index: true,
          element: <GlobalConsIndex />,
        },
        {
          path: "commune",
          element: <GlobalConsIndex />,
        },
        {
          path: "batiments",
          element: <BatimentsConsIndex />,
        },
        {
          path: "armoires",
          element: <ArmoiresConsIndex />,
        },
        {
          path: "compteurs",
          element: <CompteursConsIndex />,
        },
        {
          path: "vehicules",
          element: <VehiculesConsIndex />,
        },
      ],
    },
    {
      path: "/dashboards",
      element: <Outlet />,
      children: [       
        {
          path: "eclairage",
          element: <PatrimoineDashboardIndex level={1} />,
        },
        {
          path: "batiments",
          element: <PatrimoineDashboardIndex level={2} />,
        },
        {
          path: "parc",
          element: <PatrimoineDashboardIndex level={3} />,
        },
        {
          path: "commune",
          element: <CommuneDashboardIndex fromPublic={false} />,
        },
        {
          path: "gouvernorat",
          element: <GouvDashboardIndex fromPublic={false} />,
        },
        {
          path: "national",
          element: <NationalDashboardIndex fromPublic={false} />,
        },
      ]
    },
    {
      path: "/help",
      element: <HelpIndex />,
    }
  ];

  return routes;
}

export default PrivateRoutes;
